import styles from "../styles/home.module.css";
import { HOME_VIEW_TYPES } from "@utils/constants";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

const TopSection = dynamic(() => import("../components/topSection"));
const DemoVideoItem = dynamic(() => import("../components/demoVideoItem"));
const BannerItems = dynamic(() => import("../components/bannerSlider"));
const ReviewItems = dynamic(() => import("../components/reviewItems"));
const BlogItems = dynamic(() => import("../components/blogItems"));
const PujaItems = dynamic(() => import("../components/pujaItems"));

export default function HomeView({ homeData }) {
  const router = useRouter();
  const demoVideoRef = useRef(null);
  const bottomRef = useRef(null);
  const [shouldShowMore, setShouldShowMore] = useState(false);

  let currentLink = "/" + router.pathname.split("/")[1];
  const { params } = router.query;

  params?.map((query) => {
    currentLink += `/${query}`;
  });

  const getHomeItem = (item, index) => {
    const viewType = item.viewType;

    switch (viewType) {
      case HOME_VIEW_TYPES.ELEMENT_KRIYA_BANNER:
        return (
          <BannerItems
            key={index.toString()}
            bannerData={item.kriyaBannerData}
            position={"home_banner"}
          />
        );
      case HOME_VIEW_TYPES.ELEMENT_DEMO_VIDEO:
        return (
          shouldShowMore && (
            <DemoVideoItem
              key={index.toString()}
              data={item.demoVideoData}
              demoVideoRef={demoVideoRef}
            />
          )
        );
      case HOME_VIEW_TYPES.ELEMENT_KRIYA_LIST:
        return (
          shouldShowMore && (
            <PujaItems key={index.toString()} data={item.kriyaListData} />
          )
        );
      // case HOME_VIEW_TYPES.ELEMENT_COMMUNITY:
      //   return (
      //     <CommmunityItems
      //       communitiesState={item.communitiesData}
      //       toggleModal={handleOpenUnauthorizedModal}
      //       setCommunityID={setCommunityID}
      //     />
      //   );
      case HOME_VIEW_TYPES.ELEMENT_REVIEW:
        return (
          shouldShowMore && (
            <ReviewItems key={index.toString()} data={item.reviewData} />
          )
        );
      case HOME_VIEW_TYPES.ELEMENT_BLOG:
        return (
          shouldShowMore && (
            <BlogItems
              key={index.toString()}
              data={item.blogsListData.slice(0, 5)}
              viewName={item.viewName}
            />
          )
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (bottomRef.current) {
        const rect = bottomRef.current.getBoundingClientRect();
        if (rect.top < window.innerHeight * 1.2) {
          setShouldShowMore(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={styles.Home}>
        <TopSection demoVideoRef={demoVideoRef} />
        {homeData.map(getHomeItem)}
        {/* Invisible element to track scroll */}
        <div
          ref={bottomRef}
          style={{ height: "1px", visibility: "hidden" }}
        ></div>
      </div>
    </>
  );
}
