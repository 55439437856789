import axios from "axios";
import guestUserData from "./user/guestUserData";

export const getUserData = async (cookies) => {
  // const response = await axios.get('/api/user');
  const response = await axios.post("/api/user", {
    data: cookies,
  });
  const uData = response?.data?.data;

  if (uData) {
    delete uData["UserAddress"];
    delete uData["UserDetail"];
    delete uData["UserMetric"];

    return uData;
  }

  return guestUserData;
};
